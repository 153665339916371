export default defineNuxtPlugin(() => {
    const { callHandler, showCallPopup } = useCallHandler();

    window.addEventListener('message', (event) => {
        if (event.data.type === 'IDB2B_CALL_NUMBER') {
            console.log('Received call request:', event.data);
            callHandler(formatPhoneNumber(event.data.number));
            showCallPopup.value = true;
        }
    });
});
